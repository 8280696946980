import * as React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import '../styles/vendor/normalize.scss';
import '../styles/components/breakpoints.scss';
import '../styles/components/animations.scss';
import '../styles/components/base.scss';
import '../styles/components/grid.scss';
import '../styles/components/text-align.scss';
import '../styles/components/type.scss';
import '../styles/components/z-index.scss';
import '../styles/components/spacers.scss';
import '../styles/components/utilities.scss';
import '../styles/components/themes.scss';
import '../styles/components/btn.scss';

const TemplateWrapper = ({ children }) => {
  return (
    <>
      <Navbar />
      <>{children}</>
      <Footer />
    </>
  );
};

export default TemplateWrapper;
