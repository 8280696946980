module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-layout-virtual-aa8c50367c/3/buildhome/.yarn/berry/cache/gatsby-plugin-layout-npm-4.12.0-6ae00ab339-10c0.zip/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-ea93c4c602/3/buildhome/.yarn/berry/cache/gatsby-plugin-google-gtag-npm-5.12.3-85fa3cfd65-10c0.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-30460332-1"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"delayOnRouteUpdate":0,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-ce1873b84e/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
