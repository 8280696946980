import * as React from "react";

const Footer = class extends React.Component {
  render() {
    return (
      <footer id="footer" className="gc" style={{display: 'none'}}>
        <div className="gr">
          <div className="gi">

          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
