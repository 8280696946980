import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import NavLink from './NavLink';
import "../styles/components/Navbar.scss";

const Navbar: FC<NavbarProps> = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [activeClass, setActiveClass] = useState<string>("");
  const [windowWidth, setWindowWidth] = useState(1024);

  // The toggling function
  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const specificScrollPos = 100; // change this to your desired scroll point

    if (currentScrollPos > specificScrollPos) {
        document.getElementById('header')?.classList.add('scrolled');
    } else {
        document.getElementById('header')?.classList.remove('scrolled');
    }
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleNav = () => {
    setIsActive(!isActive);
    const newActiveClass = isActive ? "" : "is-active";
    setActiveClass(newActiveClass);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    const handleResize = () => {

      if (window.innerWidth > 1200) {
        setIsActive(false);
        // console.log(window.innerWidth)
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header id="header">
      <div className="gc">
        <div className="gr">
          <div className="gi-12">
            <Link to="/" className="logo" activeClassName="active">
              SQUARE MILL
            </Link>

            <span
              data-target="main-nav"
              role="menuitem"
              tabIndex={0}
              onKeyPress={() => toggleNav()}
              onClick={() => toggleNav()}
              id="main-nav--toggle"
              className={`navbar-burger burger ${isActive ? "is-active" : ""}`}>
              <span className="visually-hidden">Menu</span>
            </span>

            <div id="main-nav" className={isActive ? "is-active" : ""}>
              <ul>
                <NavLink
                  onClick={() => toggleNav()}
                  to="/"
                  title="Home" />
                <NavLink
                  onClick={() => toggleNav()}
                  to="/projects"
                  title="Work" />
                <NavLink
                  onClick={() => toggleNav()}
                  to="/about"
                  title="About" />
                <NavLink
                  onClick={() => toggleNav()}
                  to="/expertise"
                  title="Expertise" />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Navbar;